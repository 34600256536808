import React, { Fragment } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';
import QueryService from '../../services/QueryService';
import CampaignService from '../../services/CampaignService';
import MessageService from '../../services/MsgService';
import { useHistory } from 'react-router';
import swal from 'sweetalert';


const DeleteModal = ({ openDeleteModal,
    setOpenDeleteModal,
    deleteValue,
    setDeleteValue,
    getQueryPage,
    from,
    setBodyMessageValue,
    setSubjectValue,
    setIncludeFeedback,
    setFeedBackValue,
    setSubjectValueInSpanish,
    setBodyValueInSpanish,
    setFeedBackValueInSpanish
}) => {

    let history = useHistory();

    const deleteQuery = () => {
        let objForSaved = {
            action: 'delete',
            result_id: deleteValue.id
        }
        // let objForSchool = {
        //     // action: 'delete',
        //         id: deleteValue.id
        // }
        let objforCampaign = {
            campaign_id: deleteValue.id
        }
        let objForBanner = {
            action: "delete",
            banner_id: deleteValue && deleteValue.id
        }
        if (from === "saved-query") {
            QueryService.deleteQuery(objForSaved)
                .then(data => {
                    setDeleteValue()
                    setOpenDeleteModal(false)
                    history.push('/recruiter/school-query')
                })
                .catch(error => {
                    console.error('error from the delete', error)
                })
        } else if (from === "school-query") {
            QueryService.deleteQueryForSchool(deleteValue.id)
                .then(data => {
                    setDeleteValue()
                    getQueryPage()
                    setOpenDeleteModal(false)
                    if(data.status==200){
                        swal({
                          text: data?.data?.Status,
                          icon: "success",
                          buttons: {
                            OK: true,
                          },
                          closeOnClickOutside: false,
                        })}
                        else{
                          swal({
                            text: `Something Went Wrong.`,
                            icon: "error",
                            buttons: {
                              OK: true,
                            },
                            closeOnClickOutside: false,
                          })
                        }
                })
                .catch(error => {
                    console.error('error from the delete', error)
                })
        } else if (from === "campaign") {
            CampaignService.deleteCampaign(objforCampaign)
                .then(data => {
                    setDeleteValue()
                    getQueryPage()
                    setOpenDeleteModal(false)
                })
                .catch(error => {
                    console.error('error from the delete', error)
                    setOpenDeleteModal(false)
                })
        } else if (from === "banner") {
            CampaignService.knowBannerDeleteOrNot(objForBanner)
                .then(data => {
                    setDeleteValue();
                    getQueryPage();
                    setOpenDeleteModal(false);
                })
                .catch(error => {
                    console.error('error from the delete', error)
                    setOpenDeleteModal(false)
                })
        } else if (from === "message-template") {
            let body={
             id:deleteValue.id
            }
            MessageService.deleteCannedMessageTemplate(body)
                .then(data => {
                    setOpenDeleteModal(false)
                    getQueryPage();
                    setDeleteValue('');
                    setBodyMessageValue('');
                    setIncludeFeedback();
                    setSubjectValue('');
                    setFeedBackValue();
                    setFeedBackValueInSpanish();
                    setSubjectValueInSpanish();
                    setBodyValueInSpanish();

                    swal({
                        title: "Deleted!",
                        text: "Template has been deleted successfully",
                        icon: "success",
                        button: "OK",
                    });
                })
                .catch(error => {
                    console.log("error from the service", error)
                    swal({
                        title: "Error!",
                        text: "Failed to delete template",
                        icon: "error",
                        button: "OK",
                    });
                })
        }
    }
    return (
        <Fragment>
            <Modal
                isOpen={openDeleteModal}
                toggle={() => setOpenDeleteModal(false)}
                centered={true}
            >
                <ModalBody>
                    <div style={{ justifyContent: 'space-between', padding: '30px', alignItems: 'start', display: 'flex' }}>
                        <div>
                            <p style={{ marginLeft: '30px', fontSize: '16px', textTransform: "capitalize", fontWeight: 'bold', wordBreak: 'break-all' }} className="no-margin-bottom color">
                                {`Are you sure you want to delete ${from !== "message-template" && deleteValue && deleteValue.name ? deleteValue.name : deleteValue && deleteValue.table_name ? deleteValue.table_name : ''}`}
                            </p>
                        </div>
                        <div onClick={() => setOpenDeleteModal(false)} className="cursor">
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="flex" style={{ justifyContent: 'space-evenly', padding: '0 0 30px' }}>
                        <button
                            className="modal-button-active"
                            onClick={deleteQuery}
                        >
                            Yes
                        </button>
                        <button className="modal-button-inactive" onClick={() => setOpenDeleteModal(false)}>
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}
export default DeleteModal