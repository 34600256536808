import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./Login.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { useHistory, withRouter } from "react-router";
import Validation from "../../services/Validation";
import AuthService from "../../services/AuthService";
import Storage from "../../services/Storage";
import CommonButton from "../../components/CommonButton/CommonButton";
import { userInfoAction } from "../../reducers/UserReducer";
import { Roles } from "../../utils/Roles";
import Loading from "../../components/Loading/Loading";
import swal from "sweetalert";
import { CommonMessages } from "../../constants/CommonMessages";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Privacy from "./Terms&Conditions/privacy-policy";
import UseAcceptance from "./Terms&Conditions/use-acceptance";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const Login = () => {
  //console.log("loging called");
  let userToken = Storage.getTokenInCookie() || null;
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
  };
  let history = useHistory();
  let dispatch = useDispatch();
  let userData = useSelector((state) => state.UserReducer.userObj);
  const initState = { userEmail: "", userPassword: "" };
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState(initState);
  const [showPswd, setShowPswd] = useState(false);
  const [keepMeLoggenIn, setKeepMeLoggenIn] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [acceptanceModal, setAcceptanceModal] = useState(false);

  useEffect(() => {
    const isCheck = Storage.getKeepMeLoggedInCookie();

    if (userToken) {
      if (
        userData?.role === Roles.STUDENT_NAME &&
        userData?.is_sch_email_verified === true
      ) {
        history.push("/student/goal/cc-requirement");
      } else if (
        userData?.role === Roles.STUDENT_NAME &&
        userData?.is_sch_email_verified === false
      ) {
        history.push("/student/dashboard");
      } else if (userData?.role === Roles.PARENT_NAME) {
        history.push("/parent/goal/cc-requirement");
      } else if (
        userData?.role === Roles.COUNSELOR_NAME &&
        userData?.is_super_counselor === true
      ) {
        history.push("/super_admin/all-students");
      } else if (userData?.role === Roles.COUNSELOR_NAME) {
        history.push("/counselor/all-students");
      } else if (userData?.role === Roles.RECRUITER_NAME) {
        history.push("/recruiter/search-query");
      } else if (userData?.role === Roles.ADMIN_NAME) {
        history.push("/admin/export-students");
      } else if (
        userData?.role === Roles.SCHOOL_IT_NAME &&
        userData?.is_setup === false
      ) {
        history.push("/school_it/student_statistics");
      } else if (
        userData?.role === Roles.SCHOOL_IT_NAME &&
        userData?.is_setup === true
      ) {
        history.push("/school_it/school_setup");
      }
    }
    if (isCheck == "true") {
      const data = Storage.getLoginDataInCookie();
      setLoginData({
        userEmail: data.email,
        userPassword: data.password,
      });
      setKeepMeLoggenIn(true);
    }
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;
    const lowercasedValue = name === "userEmail" ? value.toLowerCase() : value;
    setLoginData({ ...loginData, [name]: lowercasedValue });
  };

  const setKeepMeLoggedIn = (value) => {
    Storage.setKeepMeLoggedInCookie(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit");
    let { userEmail, userPassword } = loginData;
    Storage.setLoginDataInCookie(loginData.userEmail, loginData.userPassword);
    if (keepMeLoggenIn === true) {
      setKeepMeLoggedIn(true);
    } else {
      setKeepMeLoggedIn(false);
    }
    // let emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // if (userEmail && userEmail.length > 0 && !userEmail.match(emailRegEx)) {
    //   swal("", CommonMessages.emailError, "error");
    // } else {
      const obj = {
        username: userEmail,
        password: userPassword,
        // app: "presure_pace",
        // app_version: "1.0",
      };
      setLoading(true);
      await AuthService.login(obj)
        .then(async (data) => {
          const response = data.data;
          const userDetail = response;

          Storage.setTokenInCookie(userDetail.token);
          Storage.setSessionId(userDetail.sessionid);

          if (userDetail.role === Roles.RECRUITER_NAME) {
            localStorage.setItem("user", JSON.stringify(userDetail));
            dispatch(userInfoAction(userDetail));
            //console.log("recruiter: ");
            history.push("/recruiter/search-query");
          } else if (
            userDetail.role == Roles.STUDENT_NAME &&
            userDetail?.is_sch_email_verified === true
          ) {
            dispatch(userInfoAction(userDetail));
            history.push("/student/goal/cc-requirement");
          } else if (
            userDetail.role == Roles.STUDENT_NAME &&
            userDetail?.is_sch_email_verified === false
          ) {
            dispatch(userInfoAction(userDetail));
            history.push("/student/dashboard");
          } else if (userDetail.role == Roles.PARENT_NAME) {
            dispatch(userInfoAction(userDetail));
            history.push("/parent/goal/cc-requirement");
          } else if (userDetail.role == Roles.ADMIN_NAME) {
            localStorage.setItem("user", JSON.stringify(userDetail));
            dispatch(userInfoAction(userDetail));
            history.push("/admin/export-students");
          } else if (
            userDetail?.role === Roles.SCHOOL_IT_NAME &&
            userDetail?.is_setup === false
          ) {
            localStorage.setItem("user", JSON.stringify(userDetail));
            dispatch(userInfoAction(userDetail));
            history.push("/school_it/student_statistics");
          } else if (
            userDetail?.role === Roles.SCHOOL_IT_NAME &&
            userDetail?.is_setup === true
          ) {
            localStorage.setItem("user", JSON.stringify(userDetail));
            dispatch(userInfoAction(userDetail));
            history.push("/school_it/school_setup");
          } else if (
            userDetail.role == Roles.COUNSELOR_NAME &&
            userDetail.is_super_counselor == true
          ) {
            localStorage.setItem("user", JSON.stringify(userDetail));
            dispatch(userInfoAction(userDetail));
            history.push("/super_admin/all-students");
          } else if (userDetail.role == Roles.COUNSELOR_NAME) {
            localStorage.setItem("user", JSON.stringify(userDetail));
            dispatch(userInfoAction(userDetail));
            history.push("/counselor/all-students");
          }

          setLoading(false);
        })
        .catch((error) => {
          console.log("catch block catch error", error);
          swal(
            "",
            error?.response?.data?.message ||
              "Oops! Something went wrong. Please try again",
            "error"
          );
          setLoading(false);
        });
    // }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Container fluid className="loginContainer m-0 p-0">
        <Row className="h-100 no-gutters overflow-hidden">
          <Col lg="6" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...settings}>
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <div className="slide-img-bg" />
                  <div className="slider-content">
                    <img
                      src={IMG_URL + "white-logo.png"}
                      alt="img"
                      style={{
                        width: "35%",
                        margin: "auto",
                        paddingBottom: "30px",
                      }}
                    />
                    <img
                      src={IMG_URL + "qr-code.png"}
                      alt="img"
                      style={{ width: "70%", margin: "auto" }}
                    />
                  </div>
                </div>
              </Slider>
            </div>
          </Col>
          <Col
            lg="6"
            className="d-flex justify-content-center align-items-center"
          >
            <div className="loginArea">
              <div className="slider-content">
                {windowWidth <= 995 ? (
                  <img
                    src={IMG_URL + "logo-large.png"}
                    alt="img"
                    style={{
                      width: "50%",
                      marginLeft: "30%",
                      paddingTop: "10px",
                      paddingBottom: "20px",
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              <h4 className="signin-title">
                {" "}
                Please sign in with your username/password
              </h4>
              <Form
                className="loginForm"
                onSubmit={handleSubmit}
                // autoComplete='off'
              >
                <Row>
                  <Col md={12}>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Username <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        // pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                        placeholder="Enter your username"
                        type="email"
                        name="userEmail"
                        value={loginData.userEmail || ""}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="text-left position-relative">
                      <Label className="customLabel">
                        Password <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        placeholder="Enter your password"
                        type={showPswd ? "text" : "password"}
                        name="userPassword"
                        // minLength="8"
                        value={loginData.userPassword || ""}
                        onChange={handleChange}
                      />
                      <FontAwesomeIcon
                        icon={showPswd ? faEye : faEyeSlash}
                        onClick={() => setShowPswd(!showPswd)}
                        style={{
                          position: "absolute",
                          right: "13px",
                          top: "41px",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex justify-content-between">
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          name="check"
                          id="checkbox"
                          checked={keepMeLoggenIn}
                          onChange={() => setKeepMeLoggenIn(!keepMeLoggenIn)}
                        />
                        <Label for="exampleCheck" check>
                          Remember me
                        </Label>
                      </FormGroup>
                      <Link
                        to="forgot-password"
                        style={{
                          color: "#083ABF",
                        }}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </Col>
                  {/* {!(
                    errResponseMessage === null || errResponseMessage === ""
                  ) && <div className="error">{errResponseMessage}</div>} */}
                </Row>

                <div className="d-flex align-items-center mt-5">
                  <CommonButton
                    name="Sign In"
                    type="submit"
                    btnClass="primaryBtn w-100"
                    btnHeight="40px"
                    // onClick={this.login}
                  />
                  {/* <CommonButton
                    // name='Login'
                    type="submit"
                    btnClass='primaryBtn w-100'
                    btnHeight='40px'
                  // onClick={this.login}
                  >
                    Sign In
                    &nbsp; {this.state.isLoading && <CustomSpinner />}
                  </CommonButton> */}
                </div>
              </Form>
              <div className="registerLinkWrap text-center">
                <span>Don't have an account? </span>
                <Link className="registerLink" to="lets-get-start">
                  Register
                </Link>
              </div>
              <div style={{ textAlign: "center", marginTop: "130px" }}>
                <span
                  className="privacyText"
                  onClick={() => setPrivacyModal(true)}
                >
                  Privacy Policy
                </span>
                &nbsp; | &nbsp;
                <span
                  className="privacyText"
                  onClick={() => setAcceptanceModal(true)}
                >
                  Terms of Use
                </span>
                &nbsp; | &nbsp;
                <span className="privacyText">
                  <a
                    href="https://frontend.siembramobile.com/WebUserguide/Reg/index.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Guide
                  </a>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {privacyModal && (
        <Privacy setIsOpen={setPrivacyModal} isOpen={privacyModal} />
      )}
      {acceptanceModal && (
        <UseAcceptance
          setIsOpen={setAcceptanceModal}
          isOpen={acceptanceModal}
        />
      )}
    </>
  );
};

export default Login;
