import React, { Fragment, useState, useEffect } from 'react';
import CustomLoader from '../customLoader/CustomLoader';
import MessageService from '../../services/MsgService';
import DeleteModal from '../deleteModal/DeleteModal';
import MessageTemplateService from '../../services/MessageTemplateService';
import CustomPagination from '../pagination/Pagination';
import { useSelector } from "react-redux";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const MessageTemplate = () => {
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState();
    const user = useSelector((state) => state.UserReducer?.userObj);
    const [page, setPage] = useState(1);
    const [subjectValue, setSubjectValue] = useState("");
    const [languageSelectedFromList, setLanguageSelectedFromList] = useState('');
    const [editValue, setEditValue] = useState();
    const [bodyMessageValue, setBodyMessageValue] = useState('');
    const [languageSelect, setLanguageSelect] = useState('en');
    const [includeFeedBack, setIncludeFeedback] = useState(false);
    const [openDeleteModal, setOpendeleteModal] = useState(false);
    const [feedbackValue, setFeedBackValue] = useState();
    const [showBodyTranslating, setShowBodyTranslating] = useState(false);
    const [showSubjectTranslating, setShowSubjectTranslating] = useState(false);
    const [bodyValueInSpanish, setBodyValueInSpanish] = useState();
    const [subjectValueInSpanish, setSubjectValueInSpanish] = useState();
    const [sectionValueInSpanish, setSectionValueInSpanish] = useState();
    const [feedBackValueInSpanish, setFeedBackValueInSpanish] = useState();
    // const [sectionSelect, setSectionSelect] = useState('select section');
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null);

    const capitalizeWords = (str) => {
        if (!str) return '';
        return str.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const getPageData = () => {
        setLoading(true);
        const fetchPageData = user?.role === 'recruiter'
            ? MessageService.getCannedMsgPage(user?.role, user?.college, page)
            : MessageService.getCannedMsgPage(user?.role, user?.school_id, page);

        fetchPageData
            .then((data) => {
                setPageData(data.data);
                setLoading(false);
                setIncludeFeedback(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error:", error);
            });
    };

    const handleSelect = (item, language, index) => {
        setSelectedTemplateIndex(index);

        // Set feedback checkbox based on ackError
        setIncludeFeedback(item?.answers?.["1"]?.ackError || false);

        // Set feedback text based on language
        if (item?.answers?.["1"]?.ackError && item?.answers?.text) {
            if (language === 'en') {
                setFeedBackValue(item.answers.text.en || '');
            } else {
                setFeedBackValue(item.answers.text.es || '');
            }
        } else {
            setFeedBackValue('');
        }

        if (language === 'en') {
            setEditValue(item);
            setSubjectValue(item?.messages?.en?.subject);
            setBodyMessageValue(item?.messages?.en?.body);
            // setSectionSelect(item?.messages?.en?.section || 'select section');
            setLanguageSelect('en');
            setLanguageSelectedFromList('en');
        } else if (language === 'es') {
            setEditValue(item);
            setSubjectValue(item?.messages?.es?.subject);
            setBodyMessageValue(item?.messages?.es?.body);
            // setSectionSelect(item?.messages?.es?.section || 'select section');
            setLanguageSelect('es');
            setLanguageSelectedFromList('es');
        }
    };

    const renderFeedbackInput = () => {
        if (!includeFeedBack) return null;

        return (
            <div className="feedback-inputs">
                <input
                    type="text"
                    className="feedback-input"
                    placeholder={languageSelect === 'en' ? "Enter feedback in English" : "Enter feedback in Spanish"}
                    onChange={(e) => setFeedBackValue(e.target.value)}
                    value={feedbackValue}
                    disabled={languageSelectedFromList === 'es'}
                />
            </div>
        );
    };


    const handleLanguageSelect = (e) => {
        setLanguageSelect(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        switch (name) {
            case "Subject":
                setSubjectValue(value);
                break;
            case "Body-Message":
                setBodyMessageValue(value);
                break;
            case "IncludeFeedback":
                setIncludeFeedback(checked);
                break;
            // case "Section":
            //     setSectionSelect(value);
            //     break;
        }
    };

    const saveTemplateForCreatingNew = async () => {
        if (!(subjectValue && subjectValue.trim() && bodyMessageValue && bodyMessageValue.trim())) return;

        setShowSubjectTranslating(true);
        setShowBodyTranslating(true);

        try {
            const [subjectTranslation, bodyTranslation, sectionTranslation, feedbackTranslation] = await Promise.all([
                MessageTemplateService.translateTemplate({
                    text: subjectValue,
                    to: 'es'
                }),
                MessageTemplateService.translateTemplate({
                    text: bodyMessageValue,
                    to: 'es'
                }),
                // MessageTemplateService.translateTemplate({
                //     text: sectionSelect,
                //     to: 'es'
                // }),
                includeFeedBack && feedbackValue ?
                    MessageTemplateService.translateTemplate({
                        text: feedbackValue,
                        to: 'es'
                    }) : Promise.resolve(null)
            ]);

            const body = {
                messages: {
                    en: {
                        body: bodyMessageValue,
                        subject: subjectValue,
                        // section: sectionSelect
                    },
                    es: {
                        body: bodyTranslation.data.tr,
                        subject: subjectTranslation.data.tr,
                        // section: sectionTranslation.data.tr
                    }
                },
                answers: {
                    "1": {
                        "ack": 0,
                        "ackError": includeFeedBack
                    },
                    "text": {
                        "en": feedbackValue || "",
                        "es": feedbackTranslation?.data?.tr || ""
                    },
                    "textError": false
                },
                college: user && user.college,
                languages: ["en", "es"]
            };

            await MessageTemplateService.getCannedMessageForTemplateAfterCreatingNew(body);
            resetForm();
            getPageData();

        } catch (error) {
            console.error("Error creating template:", error);
        } finally {
            setShowSubjectTranslating(false);
            setShowBodyTranslating(false);
        }
    };

    const saveTemplateForEdit = async () => {
        if (!(subjectValue && subjectValue.trim() && bodyMessageValue && bodyMessageValue.trim())) return;

        setShowSubjectTranslating(true);
        setShowBodyTranslating(true);

        try {
            const [subjectTranslation, bodyTranslation, sectionTranslation, feedbackTranslation] = await Promise.all([
                MessageTemplateService.translateTemplate({
                    text: subjectValue,
                    to: 'es'
                }),
                MessageTemplateService.translateTemplate({
                    text: bodyMessageValue,
                    to: 'es'
                }),
                // MessageTemplateService.translateTemplate({
                //     text: sectionSelect,
                //     to: 'es'
                // }),
                includeFeedBack && feedbackValue ?
                    MessageTemplateService.translateTemplate({
                        text: feedbackValue,
                        to: 'es'
                    }) : Promise.resolve(null)
            ]);

            const body = {
                messages: {
                    en: {
                        body: bodyMessageValue,
                        subject: subjectValue,
                        // section: sectionSelect
                    },
                    es: {
                        body: bodyTranslation.data.tr,
                        subject: subjectTranslation.data.tr,
                        // section: sectionTranslation.data.tr
                    }
                },
                answers: {
                    "1": {
                        "ack": 0,
                        "ackError": includeFeedBack
                    },
                    "text": {
                        "en": feedbackValue || "",
                        "es": feedbackTranslation?.data?.tr || ""
                    },
                    "textError": false
                },
                id: editValue?.id,
                college: user && user.college,
                languages: ["en", "es"]
            };

            await MessageTemplateService.getCannedMessageForTemplateAfterEditing(body);
            resetForm();
            getPageData();

        } catch (error) {
            console.error("Error editing template:", error);
        } finally {
            setShowSubjectTranslating(false);
            setShowBodyTranslating(false);
        }
    };

    const resetForm = () => {
        setSubjectValue('');
        setBodyMessageValue('');
        // setSectionSelect('select section');
        setIncludeFeedback(false);
        setFeedBackValue('');
        setEditValue(null);
        setSelectedTemplateIndex(null);
        setLanguageSelectedFromList('');
    };

    const deleteTemplate = () => {
        setOpendeleteModal(true);
    };

    useEffect(() => {
        getPageData();
    }, [page]);

    const renderTemplateList = () => {
        return pageData?.result?.map((item, index) => (
            <div key={index} className="template-item">
                {/* <h3 className="template-section"> Section: {capitalizeWords(item?.messages?.en?.section)}</h3> */}
                <h3 className="template-subject" onClick={() => handleSelect(item, 'en', index)}>{item?.messages?.en?.subject}</h3>
                <p className="template-body">{item?.messages?.en?.body}</p>
                <div className="language-buttons">
                    <button
                        className={`lang-btn ${selectedTemplateIndex === index && languageSelectedFromList === 'en' ? 'active' : ''}`}
                        onClick={() => handleSelect(item, 'en', index)}
                    >
                        EN
                    </button>
                    <button
                        className={`lang-btn ${selectedTemplateIndex === index && languageSelectedFromList === 'es' ? 'active' : ''}`}
                        onClick={() => handleSelect(item, 'es', index)}
                    >
                        ES
                    </button>
                </div>
            </div>
        ));
    };

    const renderTemplateForm = () => (
        <div className="">
            <div className="template-creation-header">
                <h2>CREATE NEW MESSAGE TEMPLATES</h2>
            </div>

            <div className="template-form-group">
                <label style={{
                    fontWeight: 'bold',
                    display: 'block',
                    marginBottom: '8px'
                }}>
                    Language Preference
                </label>
                <select
                    disabled={languageSelectedFromList === 'es'}
                    className="language-select"
                    onChange={handleLanguageSelect}
                    value={languageSelect}
                    style={{ marginBottom: '20px', width: '100%' }}
                >
                    <option value="" disabled>Language Preference</option>
                    <option value="en">EN</option>
                    <option value="es">ES</option>
                </select>

                <label style={{
                    fontWeight: 'bold',
                    display: 'block',
                    marginBottom: '8px'
                }}>
                    Subject
                </label>
                <input
                    name="Subject"
                    className="subject-input"
                    type="text"
                    placeholder="Enter Subject"
                    value={subjectValue}
                    disabled={languageSelectedFromList === 'es'}
                    onChange={handleInputChange}
                    style={{ marginBottom: '20px', width: '100%', border: '1px solid #ddd', borderRadius: '4px' }}
                />

                <label style={{
                    fontWeight: 'bold',
                    display: 'block',
                    marginBottom: '8px'
                }}>
                    Body Message
                </label>
                <textarea
                    name="Body-Message"
                    className="body-input"
                    placeholder="Enter Body Message"
                    value={bodyMessageValue}
                    disabled={languageSelectedFromList === 'es'}
                    onChange={handleInputChange}
                    rows="6"
                    style={{ marginBottom: '20px', width: '100%', padding: '8px', border: '1px solid #ddd', borderRadius: '4px' }}
                />

                <div className="feedback-section">
                    <label className="feedback-checkbox">
                        <input
                            type="checkbox"
                            name="IncludeFeedback"
                            checked={includeFeedBack}
                            onChange={handleInputChange}
                        />
                        <span>{" "}Include feedback response</span>
                    </label>
                    {renderFeedbackInput()}
                </div>

                <div className="template-actions">
                    <button
                        onClick={editValue ? saveTemplateForEdit : saveTemplateForCreatingNew}
                        className="save-template-button"
                        style={{ marginRight: '10px' }}
                    >
                        SAVE TEMPLATE
                    </button>
                    <button
                        onClick={deleteTemplate}
                        className="delete-template-button"
                        disabled={!editValue}
                    >
                        DELETE TEMPLATE
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    {loading ? <CustomLoader /> : (
                        <div style={{ height: '100%' }}>
                            <div>
                                <p className="title-container">MESSAGE TEMPLATES</p>
                            </div>
                            <div className="template-container">
                                <div className="template-header">
                                    <img src={IMG_URL + "mail.jpg"} alt="Message" className="template-icon" />
                                    <div>
                                        <p className="template-count">{pageData?.result?.length || 0}</p>
                                        <span className="template-label">MESSAGE TEMPLATE</span>
                                    </div>
                                </div>
                                <div className="template-grid-container">
                                    <div className="template-list-container">
                                        {renderTemplateList()}
                                    </div>
                                    {renderTemplateForm()}
                                </div>
                            </div>
                            <CustomPagination pageValue={page} setPage={setPage} count={pageData} />
                        </div>
                    )}
                    {openDeleteModal && (
                        <DeleteModal
                            deleteValue={editValue}
                            openDeleteModal={openDeleteModal}
                            setOpenDeleteModal={setOpendeleteModal}
                            setDeleteValue={setEditValue}
                            getQueryPage={getPageData}
                            setBodyMessageValue={setBodyMessageValue}
                            setSubjectValue={setSubjectValue}
                            setIncludeFeedback={setIncludeFeedback}
                            setFeedBackValue={setFeedBackValue}
                            setSubjectValueInSpanish={setSubjectValueInSpanish}
                            setBodyValueInSpanish={setBodyValueInSpanish}
                            setFeedBackValueInSpanish={setFeedBackValueInSpanish}
                            from="message-template"
                        />
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default MessageTemplate;